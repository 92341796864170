import React from 'react';
import type { NextPage } from 'next'
import type { AppProps, AppContext } from 'next/app'
import App from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

import '../css/index.css';
import { AuthProvider } from 'react-oidc-context';
import { AppSettings } from '../functions/AppSettings';
import { SessionProvider } from 'next-auth/react'

type NextPageWithLayout = NextPage & {
    layout?: (page: React.ReactElement) => React.ReactNode;
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
}

function CustomApp({
    Component,
    pageProps
}: AppPropsWithLayout): JSX.Element {

    const withLayout = Component.layout ?? (page => page);
    const clientId = AppSettings.current.keycloakClientId;
    const redirect_uri = AppSettings.current.redirectUri;
    const baseUrl = AppSettings.current.keycloakBaseUrl;
    const realm = AppSettings.current.keycloakRealm;
    
    return (
        <AuthProvider
            client_id={clientId}
            redirect_uri={redirect_uri}
            authority={baseUrl+"/realms/"+realm}
            onSigninCallback={()=>{Router.push('/')}}
        >
            {
                withLayout(
                <SessionProvider session={pageProps.session}>
                    <Component {...pageProps} />
                </SessionProvider>
                )
            }
        </AuthProvider>
    );
}

// This disables the ability to perform Automatic Static Optimization... (Sadge)
// Causing every page in the app to be server-side rendered,
// but allowing the use of runtime configuration in Docker-based Environment!
CustomApp.getInitialProps = async (appContext: AppContext) => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);
    return { ...appProps };
}

NProgress.configure({
    showSpinner: false
});

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeComplete', NProgress.done);
Router.events.on('routeChangeError', NProgress.done);

export default CustomApp;
